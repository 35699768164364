:root {
  /* Define color palette */
  --bg-color: #121212;
  --obj-color: #1e1e1e;
  --obj-highlight-color: #484848;
  --obj-shadow-color: #101010;
  --tooltip-color: #080808;
  --accent-color: #4c92fc;
}

.main {
  text-align: center;
  background-color: var(--bg-color);
  margin: 0;
  padding: 0;
  color: white;
}

@media only screen and (max-width: 40rem) {
  html {
    font-size: 62.5%;
  }
}
