.placeholder {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background: linear-gradient(
    -80deg,
    rgba(255, 255, 255, 0.1) 20%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.1) 80%);
  background-size: 300% 300%;
  animation: bgPan 2s infinite linear;
}

@keyframes bgPan {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 150% 50%;
  }
}
