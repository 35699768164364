.copy {
  position: relative;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.9rem;
}

.copy:hover {
  color: var(--accent-color);
}

.tooltip {
  position: absolute;
  font-size: 0.9rem;
  background-color: var(--tooltip-color);
  padding: 0.25rem;
  border-radius: 0.25rem;
  pointer-events: none;
  text-wrap: nowrap;
  opacity: 0;
  z-index: 15;
  color: white;
  left: 50%;
  transform: translate(-50%, 0.5rem);
}

@media (prefers-reduced-motion: no-preference) {
  .tooltip {
    transition: opacity 0.15s ease-out, transform 0.15s ease-out;
  }
}

.copy:hover .icon {
  filter: brightness(80%);
}

.copy:hover .tooltip {
  opacity: 1;
  transform: translate(-50%, 0.25rem);
}
