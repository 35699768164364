.contact {
  padding-bottom: 1.5rem;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emailHeader {
  margin-top: 0;
}

.linkButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--obj-highlight-color);
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: background-color 0.1s;
}

.linkButton:hover {
  background-color: var(--accent-color);
}

.or {
  display: flex;
  flex-direction: row;
  color: gray;
  padding-top: 0.25rem;
  font-size: 0.75rem;
}

.lineLeft {
  width: 100%;
  height: 1px;
  background-color: gray;
  border: none;
  margin: auto;
  margin-right: 0.5rem;
}
.lineRight {
  width: 100%;
  height: 1px;
  background-color: gray;
  border: none;
  margin: auto;
  margin-left: 0.5rem;
}
