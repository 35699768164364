.modalTrigger {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: zoom-in;
  padding: 0;
  background-color: transparent;
  border: none;
  left: 0;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: zoom-out;
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem 1rem;
  background-color: var(--obj-color);
  border-radius: 1rem;
  max-width: min(90vw, 70rem);
  max-height: 90vh;
  cursor: default;
}

.modalBody:focus {
  outline: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.75rem;
  color: white;
  font-weight: 600;
  font-size: 1.3rem;
}

.imageNumber {
  text-align: center;
  color: gray;
}

.xButton {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.xButton:hover {
  filter: brightness(150%);
}

.xButton:active {
  filter: brightness(80%);
}

.imageRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.image {
  max-height: 70vh;
  max-width: calc(min(90vw, 70rem) - 5rem);
  aspect-ratio: 16 / 9;
  height: auto;
}

.chevronButton {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.chevronButton:hover {
  filter: brightness(150%);
}

.chevronButton:active {
  filter: brightness(80%);
}
