.section {
  padding-top: 1rem;
  max-width: 65rem;
  margin: auto;
}

.titleBar {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.logoContainer {
  height: 4.75rem;
  border-radius: 0.5rem;
  overflow: hidden;
}

.logo {
  height: 100%;
}

.titleBarLeft {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-right: auto;
}

.titleBarRight {
  display: flex;
  flex-direction: column;
  align-items: right;
  text-align: right;
  margin-left: auto;
}

.title {
  color: var(--accent-color);
  text-align: left;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
  height: 3rem;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: white;
}

.link:hover {
  color: var(--accent-color);
}

.date {
  color: lightgray;
  text-align: right;
}

.tech {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.singleCol {
  padding: 1rem;
}

.content {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  gap: 1rem;
}

.leftCol {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 50%;
}

.rightCol {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 50%;
}

.previewContainer {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  float: right;
  height: fit-content;
  box-sizing: border-box;
}

@media only screen and (max-width: 55rem) {
  .previewContainer {
    width: 100% !important;
    float: none !important;
  }
  .preview {
    width: 90% !important;
    left: -5% !important;
    rotate: unset !important;
  }
  .preview2 {
    width: 90% !important;
    right: 0% !important;
    rotate: unset !important;
  }
}

.previewContainer:hover .preview {
  left: -5%;
  filter: brightness(50%);
  rotate: -1.5deg;
}

.previewContainer:hover .preview2 {
  right: 2.5%;
  filter: brightness(50%);
  rotate: 1.5deg;
}

@media (prefers-reduced-motion: no-preference) {
  .preview {
    transition: left 0.15s ease-out, filter 0.075s ease-out, rotate 0.15s ease-out;
  }
  .preview2 {
    transition: right 0.15s ease-out, filter 0.075s ease-out, rotate 0.15s ease-out;
  }
  .previewHover {
    transition: opacity 0.075s ease-out;
  }
}
  
.preview {
  position: relative;
  overflow: hidden;
  width: 85%;
  border-radius: 0.75rem;
  left: -2.5%;
  box-shadow: 0.2rem 0rem 0.2rem 0rem var(--obj-shadow-color);
  pointer-events: none;
  border-color: gray;
  border-width: 1px;
  border-style: solid;
}

.preview2 {
  position: absolute;
  overflow: hidden;
  width: 85%;
  border-radius: 0.75rem;
  right: 5%;
  /* clip-path: inset(0 0 0 85%); */
  pointer-events: none;
  border-color: gray;
  border-width: 1px;
  border-style: solid;
}

.previewContainer:hover .previewHover {
  opacity: 1;
}

.previewHover {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  align-content: center;
  z-index: 10;
  font-weight: 600;
  font-size: 1.25rem;
  text-shadow: 0rem 0rem 1rem black;
  pointer-events: none;
}

.textSection {
  pointer-events: none;
}

.text {
  text-align: left;
  line-height: 125%;
}
