.stack {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  margin: auto;
}

.groupName {
  margin-top: 0;
}

.icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 0.5rem;
  padding-bottom: 1.5rem;
}