.row {
  gap: 2rem;
  padding: 1rem;
  max-width: 37.5rem;
  margin: auto;
  text-align: left;
}

.picContainerContainer {
  width: 35%;
  float: right;
  padding: 1rem;
  padding-top: 3rem;
}

.picContainer {  
  position: relative;
  aspect-ratio: 3 / 4;
  border-radius: 1.5rem;
}

.pic {
  width: 100%;
  border-radius: 1.5rem;
}

.cutout {
  position: absolute;
  width: 100%;
  bottom: 3px;
  left: 0;
  border-radius: 1.5rem;
}

.firstName {
  position: absolute;
  top: -3rem;
  left: -14.75rem;
  font-style: italic;
}

.firstNameSvg {
  height: 3rem;
}

.lastName {
  position: absolute;
  top: -1rem;
  left: -21rem;
}

.lastNameSvg {
  height: 7.5rem;
}

.picClear {
  clear: both;
}

.text {
  padding: 1rem;
  padding-top: 9rem;
  padding-bottom: 0;
  line-height: 120%;
}
