.navbar {
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  background-color: var(--bg-color);

  border-bottom-style: solid;
  border-bottom-color: var(--accent-color);
  border-bottom-width: 0.1rem;
}

.left {
  margin-right: auto;
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  justify-self: center;
  padding: 0.25rem;
  text-decoration: none;
}

.logo {
  height: 2.5rem;
}

.name {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: white;
}

.firstName {
  font-weight: 600;
  font-size: 0.9rem;
}

.lastName {
  font-weight: 600;
  font-size: 1.1rem;
}

.right {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: left;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  border-radius: 0rem;
}

.link:hover {
  background-color: var(--obj-color);
}
