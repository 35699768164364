.jobs {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 55rem;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

.job {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  box-sizing: border-box;
  color: white;
  padding: 1rem;
  border-radius: 1rem;
  background-color: var(--obj-color);
  box-shadow: 0rem 0rem 0.25rem 0.25rem var(--obj-shadow-color);
  cursor: pointer;
  text-decoration: none;
}

.job:hover {
  background-color: var(--obj-highlight-color);
}

.job:focus {
  outline: none;
}

.outline {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 1rem;
  pointer-events: none;
}

.job:focus .outline {
  outline-style: solid;
}

.logoContainer {
  width: 12rem;
  aspect-ratio: 1;
  border-radius: 0.75rem;
  box-sizing: border-box;
  overflow: hidden;
}

.logo {
  width: 100%;
}

.text {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: top;
  box-sizing: border-box;
  align-self: right;
  text-align: left;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
}

.date {
  color: lightgray;
  font-size: 0.9rem;
}

.tech {
  margin-top: auto;
  justify-content: right;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

@media only screen and (max-width: 55rem) {
  .logoContainer {
    width: 10rem !important;
  }
  .jobs {
    max-width: 100% !important;
  }
  .job {
    border-radius: 0.75rem !important;
  }
  .outline {
    border-radius: 0.75rem !important;
  }
  .logoContainer {
    border-radius: 0.5rem !important;
  }
}
