.section {
  border-bottom-style: solid;
  border-bottom-width: 0.1rem;
  border-bottom-color: var(--accent-color);
}

.sectionTitle {
  color: var(--accent-color);
  margin-bottom: 0.75rem;
}

.footer {
  background-color: var(--bg-color);
  padding: 1rem;
  color: gray;
}
