.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: gray;
  font-size: 0.9rem;
  max-width: fit-content;
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.button:hover {
  color: var(--accent-color);
}

.button:hover .arrow {
  transform: translateX(-0.35rem);
}

.arrow {
  height: 1.25rem;
}

@media (prefers-reduced-motion: no-preference) {
  .arrow {
    transition: transform 0.1s ease-out;
  }
}
